// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.carousel-container {
    width: 100%;
    margin: 0 auto;
    max-width: 1200px;
    overflow: hidden;
  }
  
  .carousel-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
  }
  
  .carousel-video {
    width: 100%;
    height: auto;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .slick-dots li button:before {
    color: #008000;
  }
  
  .slick-prev:before, .slick-next:before {
    color: #008000;
  }
  
  .welcome-section {
    text-align: center;
    padding: 20px;
  }
  
  .btn {
    background: #008000;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  }
  
  .btn:hover {
    background: #005f00;
  }
  `, "",{"version":3,"sources":["webpack://./src/HeroCarousel.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,cAAc;IACd,iBAAiB;IACjB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,kBAAkB;IAClB,aAAa;EACf;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;IACjB,0CAA0C;EAC5C;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".carousel-container {\r\n    width: 100%;\r\n    margin: 0 auto;\r\n    max-width: 1200px;\r\n    overflow: hidden;\r\n  }\r\n  \r\n  .carousel-image {\r\n    width: 100%;\r\n    height: auto;\r\n    object-fit: cover;\r\n    border-radius: 5px;\r\n  }\r\n  \r\n  .carousel-video {\r\n    width: 100%;\r\n    height: auto;\r\n    border-radius: 5px;\r\n    object-fit: cover;\r\n  }\r\n  \r\n  .slick-dots li button:before {\r\n    color: #008000;\r\n  }\r\n  \r\n  .slick-prev:before, .slick-next:before {\r\n    color: #008000;\r\n  }\r\n  \r\n  .welcome-section {\r\n    text-align: center;\r\n    padding: 20px;\r\n  }\r\n  \r\n  .btn {\r\n    background: #008000;\r\n    color: white;\r\n    padding: 10px 20px;\r\n    text-decoration: none;\r\n    border-radius: 5px;\r\n    font-weight: bold;\r\n    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);\r\n  }\r\n  \r\n  .btn:hover {\r\n    background: #005f00;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
