import React from 'react';
import HeroCarousel from '../../src/HeroCarousel';
import '../../src/Home.css';

const Home: React.FC = () => {
  return (
    <div>
      <HeroCarousel />
      <section className="welcome-section">
        <h2>Welcome to GYANTEERTH SHIKSHA EVAM KALYAN SANSTHA</h2>
        <p><b><i>Transforming Lives Through Compassion: The Work of " GYANTEERTH SHIKSHA EVAM KALYAN SANSTHA</i></b></p>
        <p>
          Empowering communities through education, skill development, and welfare programs. 
          Join us in making a difference!
        </p>
        <a href="/donate" className="btn">Donate Now</a>
      </section>
    </div>
  );
};

export default Home;