// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-page {
    text-align: center;
    padding: 40px 20px;
  }
  
  .home-page h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .home-page p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .btn {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
  }
  
  .btn:hover {
    background: #0056b3;
  }
  `, "",{"version":3,"sources":["webpack://./src/Home.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,kBAAkB;IAClB,iBAAiB;EACnB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".home-page {\r\n    text-align: center;\r\n    padding: 40px 20px;\r\n  }\r\n  \r\n  .home-page h2 {\r\n    font-size: 2rem;\r\n    margin-bottom: 20px;\r\n  }\r\n  \r\n  .home-page p {\r\n    font-size: 1.2rem;\r\n    margin-bottom: 30px;\r\n  }\r\n  \r\n  .btn {\r\n    background: #007bff;\r\n    color: white;\r\n    padding: 10px 20px;\r\n    text-decoration: none;\r\n    border-radius: 5px;\r\n    font-weight: bold;\r\n  }\r\n  \r\n  .btn:hover {\r\n    background: #0056b3;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
