import React from 'react';

const About: React.FC = () => {
  return (
    <div className="page">
      {/* <h2>About Us</h2> */}
      <p>
      At <b><i>GYANTEERTH SHIKSHA EVAM KALYAN SANSTHA</i></b>, we are committed to empowering underprivileged communities through a combination of food, education, healthcare, and essential resources. Our work is driven by the belief that when we uplift the most vulnerable among us, we uplift society as a whole. With your support, we can continue to expand our services and reach even more individuals and families in need. Together, we can build a brighter, more compassionate world, one person at a time.
      </p>
      <section className="welcome-section">
        <a href="/home" className="journey-link">Glimpses of our Impactful Journey!!!</a>
      </section>
      <p>We aim to bring about change with the belief: <b><i>"Transforming Lives, One Act of Compassion at a Time.”</i></b> Our Work focuses on:</p>
      <div className="services-section">
  <div className="service-item">
    <h2><u>Education for a Brighter Future</u></h2>
    <p>We provide free education, essential school supplies, and mentorship to children from underprivileged backgrounds. Our programs are designed to break the cycle of poverty, empowering children with the knowledge, skills, and confidence they need to thrive.</p>
  </div>
  
  <div className="service-item">
    <h2><u>Healthcare for All</u></h2>
    <p>We organize free medical camps offering essential healthcare services, including consultations and medications. Our focus on preventive care improves health outcomes and helps individuals live healthier, more productive lives.</p>
  </div>

  <div className="service-item">
    <h2><u>Clothing and Essentials</u></h2>
    <p>Through donation drives, we provide clothing, blankets, and other essential items to those in need. These contributions help restore dignity and ensure families and children can live with basic comfort and respect.</p>
  </div>

  <div className="service-item">
    <h2><u>Feeding the Hungry</u></h2>
    <p>We provide daily meals to individuals, animals, and birds in need, ensuring that no one—human or animal—goes to bed hungry. By nourishing every life with care and compassion, we restore hope and strength, empowering all to overcome hardship and embrace a brighter tomorrow.</p>
  </div>
</div>
    </div>
  );
};

export default About;
