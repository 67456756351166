import React from 'react';

const Gallery: React.FC = () => {
  return (
    <div className="page">
      <h2>Gallery</h2>
      <p>Pictures of our events and initiatives coming soon.</p>
    </div>
  );
};

export default Gallery;
