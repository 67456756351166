import React from 'react';
import { Link } from 'react-router-dom';
import '.././Header.css';

const Header: React.FC = () => {
  return (
    <header className="header">
      <div className="logo-name-container">
        <img src="/images/GyanteerthLogoNew.png" alt="GyanTeerth Logo" className="logo" />
        <div className="ngo-name">
          <h1><span>GYAN</span><span className="spanTeerth">TEERTH</span></h1>
          <p><b>Shiksha Evam Kalyan Sansthan</b></p>
          <p><b><span className="">(NGO Registered under 80G & 12A)</span></b></p>
          {/* <h5>(NGO Registered under 80G & 12A)</h5> */}
        </div>
      </div>
      <nav className="navbar">
        <ul className="nav-links">
          <li><a href="/about">About Us</a></li>
          <li><a href="/services">Services</a></li>
          <li><a href="/gallery">Gallery</a></li>
          <li><a href="/contact">Contact</a></li>
          <li><a href="/donate">Donate</a></li>
          {/* <li><a href="/videos">Videos</a></li> */}
        </ul>
      </nav>
    </header>
  );
};

export default Header;
