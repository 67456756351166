import React from 'react';

const DonatePage: React.FC = () => {
  // Donation contact number
  const donationContact = "+91-9826537933";

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Support Our Cause</h1>
      <p>We appreciate your willingness to contribute to our NGO. Your donations help us create a meaningful impact!</p>
      
      <div style={{ margin: "20px auto", display: "inline-block", padding: "20px", borderRadius: "8px", backgroundColor: "#f9f9f9", border: "1px solid #ccc" }}>
        <h3 style={{ margin: "0 0 10px", color: "#2c6b2f" }}>Contact for Donations</h3>
        <p style={{ fontSize: "18px", margin: "0", color: "#333" }}>
          Call/Message us at: <strong>{donationContact}</strong>
        </p>
        <br/>
        <p style={{ fontSize: "18px", margin: "0", color: "#333" }}>
          UPI Id: <strong>0519100a0161853.mab@pnb</strong>
        </p>
        <br/>
        <p style=
        {{ fontSize: "16px", color: "#555", marginTop: "10px" }}>
          You can contact us to get our UPI ID or QR scanner for making donations.
        </p>
        <p style={{ fontSize: "16px", color: "#555", marginTop: "10px" }}>
          We will also provide an 80G certificate for your contribution.
        </p>
      </div>
      
      <p style={{ marginTop: "20px", color: "#555" }}>
        Your generosity helps us make a real difference in the lives of those in need. Thank you!
      </p>
    </div>
  );
};

export default DonatePage;
