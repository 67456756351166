import React from 'react';
import HeroCarousel from '../../src/HeroCarousel';
import '../../src/Home.css';

const Services: React.FC = () => {
  return (
    <div className="page">
      <h2>Our Services</h2>
      <div className="services-container">
  <div className="service-item1">
    <h3>Free Education</h3>
    <p>Providing free education to children in need.</p>
  </div>
  <div className="service-item1">
    <h3>Health and Wellness Camps</h3>
    <p>Organizing health and wellness camps to promote healthy living.</p>
  </div>
  <div className="service-item1">
    <h3>Vocational Training</h3>
    <p>Vocational training for underprivileged individuals to enhance their skills.</p>
  </div>
  <div className="service-item1">
    <h3>Tree Plantation</h3>
    <p>Awareness for sustainable environment by promoting tree plantation.</p>
  </div>
  <div className="service-item1">
    <h3>Feeding the Hungry</h3>
    <p>Providing meals to those in need, including individuals, animals, and birds.</p>
  </div>
</div>
      <div>
      <div>
      <HeroCarousel />
      <section className="welcome-section">
        <h2>Welcome to GYANTEERTH SHIKSHA EVAM KALYAN SANSTHA</h2>
        <p><b><i>Transforming Lives Through Compassion: The Work of " GYANTEERTH SHIKSHA EVAM KALYAN SANSTHA</i></b></p>
        <p>
          Empowering communities through education, skill development, and welfare programs. 
          Join us in making a difference!
        </p>
        <a href="/donate" className="btn">Donate Now</a>
      </section>
    </div>
      </div>
    </div>
    
  );
};

export default Services;
