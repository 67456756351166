import React, { useRef } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../src/HeroCarousel.css";

const HeroCarousel: React.FC = () => {

  const sliderRef = useRef<Slider | null>(null);  // Ref for the carousel slider
  const videoRef = useRef<HTMLVideoElement | null>(null);  // Ref for the video element

  const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true, // Pause the carousel on hover (optional)
  };

  const handleVideoPlay = () => {
    // Pause the carousel when the video starts playing
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
  };

  const handleVideoPause = () => {
    // Resume the carousel when the video is paused or finished
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
  };

  return (
    <div className="carousel-container">
      <Slider {...settings}  ref={sliderRef}>
        {/* Add images */}
        
        <div>
          <img src="/images/GyanteerthHarGharShiksha.png" alt="Education Drive" className="carousel-image" />
        </div>
        <div>
          <img src="/images/GarGharShikha2.png" alt="Education Drive" className="carousel-image" />
        </div>
        <div>
          <img src="/images/HarGharShiksha.png" alt="Education Drive" className="carousel-image" />
        </div>
        <div>
          <img src="/images/BookDistribution.png" alt="Education Drive" className="carousel-image" />
        </div>
        <div>
          <img src="/images/MedicalCamp1.png" alt="Community Service" className="carousel-image" />
        </div>
        <div>
          <img src="/images/MedicalCamp2.png" alt="Community Service" className="carousel-image" />
        </div>
        <div>
          <img src="/images/Medicalcamp3.png" alt="Community Service" className="carousel-image" />
        </div>
        <div>
          <img src="/images/Env1.png" alt="Community Service" className="carousel-image" />
        </div>
        <div>
          <img src="/images/Env2.png" alt="Community Service" className="carousel-image" />
        </div>
        <div>
          <img src="/images/WaterDist1.png" alt="Community Service" className="carousel-image" />
        </div>
        <div>
          <img src="/images/WaterDist2.png" alt="Community Service" className="carousel-image" />
        </div>

        {/* Add video */}
        <div>
          <video ref={videoRef}
            className="carousel-video"
            controls
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            onEnded={handleVideoPause}>
            <source src="/videos/GyanteerthVideo2024.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Add another image */}
        {/* <div>
          <img src="/images/cover3.jpg" alt="Workshop Event" className="carousel-image" />
        </div> */}
      </Slider>
    </div>
  );
};

export default HeroCarousel;
