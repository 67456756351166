import React from 'react';

const Contact: React.FC = () => {
  return (
    <div className="page">
      <h2>Contact Us</h2>
      <p>Email: : gyanteerthsiksha@gmail.com</p>
      <p>Phone: +91-9826537933</p>
      <p>Address: Lashkar Gwalior, Madhya Pradesh</p>
    </div>
  );
};

export default Contact;
