// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    background: #ffffff;
    color: darkgreen;
    text-align: center;
    padding: 15px 0;
    border-top: 1px solid #e0e0e0;
    font-size: 0.9rem;
  }
  `, "",{"version":3,"sources":["webpack://./src/Footer.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,eAAe;IACf,6BAA6B;IAC7B,iBAAiB;EACnB","sourcesContent":[".footer {\r\n    background: #ffffff;\r\n    color: darkgreen;\r\n    text-align: center;\r\n    padding: 15px 0;\r\n    border-top: 1px solid #e0e0e0;\r\n    font-size: 0.9rem;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
